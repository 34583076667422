.custom-select-box {
  position: relative;
  display: flex;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: auto;
  height: 38px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;

  &:after {
    content: '';
    display: block;
    width: 6.5px;
    height: 6.5px;
    border: 2.5px solid #000000;
    border-top: 0;
    border-right: 0;
    position: absolute;
    right: 8px;
    top: calc(50% - 1.5px);
    transform: translate(0, -50%) rotate(-45deg);
  }

  .select-label {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 22px;
    padding-left: 8px;
    height: auto;
    border: none;
    outline: none;
    background: transparent;
  }

  .option-list {
    position: absolute;
    width: 100%;
    top: 100%;
    list-style-type: none;
    background-color: #FFFFFF;
    padding: 0;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-top: 0.25rem;
    z-index: 10;
    .option-item {
      text-align: left;
      padding: 6px 16px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: rgba(130, 0, 255, 0.15);
        color: #8200FF;
      }
    }
  }

  .disable {
    cursor: unset;
    &:hover {
      background-color: unset;
      color: unset;
    }
  }

  &.th-select-box {
    height: auto;

    .select-label {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 22px;
      padding-left: 8px;
      height: auto;
      border: none;
      outline: none;
      background: transparent;
    }

    .option-list {
      border-radius: 0;
      margin-top: 0;
      border: 1px solid #000000;
      .option-item {
        padding: 3px 10px;
        border-radius: 0;
        &:hover {
          background-color: #A14EF2;
          color: #FFFFFF;
        }
      }
    }
  }

  &.option-scroll-1,
  &.option-scroll-2,
  &.option-scroll-3,
  &.option-scroll-4 {
    .option-list {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: #666666;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #DDDDDD;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }

  &.option-scroll-1 {
    .option-list {
      max-height: 100px;
    }
  }
  &.option-scroll-2 {
    .option-list {
      max-height: 200px;
    }
  }
  &.option-scroll-3 {
    .option-list {
      max-height: 300px;
    }
  }
  &.option-scroll-4 {
    .option-list {
      max-height: 400px;
    }
  }
}