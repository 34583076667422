.page-button {
  justify-content: center;
  button {
    min-width: 3.8rem;
  }
  .btn-sm {
    min-width: 2.8rem;
  }
  .btn-lg {
    min-width: 4.4rem;
  }
}