.sns-contents {
  .sns-contents-wrap {
    .btn-wrap {
      text-align: end;
    }
    .table-wrap {
      table {
        thead {
          .th-num {
            width: 110px;
          }
          .th-btn {
            width: 98px;
          }
        }
        tbody {
          td {
            vertical-align: middle;
          }
          .td-btn {
            padding: 4px;
          }
          .contents-wrap {
            display: flex;
            gap: 16px;
            cursor: pointer;

            .image-form {
              width: 100px;
              max-width: 100px;
              height: 100px;
              max-height: 100px;
              border-radius: 0.25rem;
              background-color: black;

              img {
                width: 100px;
                max-width: 100px;
                height: 100px;
                max-height: 100px;
                object-fit: contain;
                border-radius: 0.25rem;
              }
            }
            .contents-data {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 10px;
              max-width: calc(100% - 124px);

              .contents-title {
                font-size: 16px;
                font-weight: 600;
                line-height: 1.3;
                white-space: normal;
                overflow: hidden;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .contents-influencer-data {
                display: flex;
                align-items: center;
                gap: 10px;

                .image-form-influencer {
                  width: 38px;
                  max-width: 38px;
                  height: 38px;
                  max-height: 38px;
                  border-radius: 50%;
                  img {
                    width: 38px;
                    max-width: 38px;
                    height: 38px;
                    max-height: 38px;
                    border-radius: 50%;
                  }
                }

                .text-data {
                  &> div {
                    font-size: 13px;
                    line-height: 1.3;
                  }
                }

                .sns-icon {
                  width: 38px;
                  height: 38px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.sns-contents-detail {
    .divider-col {
    height: auto;
    width: 2px;
    background-color: #999999;
  }

    .dashboard-title {
      font-size: 16px;
      font-weight: 600;
    }

    .comment-dashboard-card {
      .comment-dashboard {
        gap: 20px;

        .dashboard-title {
          margin-bottom: 10px;
        }
      }
    }
  }
}