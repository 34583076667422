.tab-dashboard-wrapper {
  .tab-wrap {
    display: flex;
    border-bottom: 1px solid #999999;
    .tab {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      cursor: pointer;

      &.active {
        background-color: #333333;
        color: white;
      }
    }

    .tab + .tab {
      border-left: 1px solid #999999;
    }
  }
}