.user-detail {
  .title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .main-wrap {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .user-info {
    .form-group {
      margin: 0;
    }
  }

  .user-summary {
    section {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .summary-warp {
      gap: 16px;

      .summary {
        flex: 1;
        text-align: center;
        .key {
          font-size: 16px;
          font-weight: bold;
        }

        .value {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }

  .filtering-wrap {
    padding: 10px 0.5rem;
    margin-bottom: 0;
  }

  .service-list-wrap {
    .service-main-wrap {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .service-wrap {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
      button {
        flex: 1;
      }
    }
    table {
      thead {
        th {
          text-align: center;
        }
        .th-date {
          min-width: 120px;
          width: 120px;
        }
        .th-s {
          min-width: 100px;
          width: 100px;
        }
        .th-m {
          min-width: 150px;
          width: 150px;
        }
      }

      tbody {
        td {
          text-align: center;
        }
      }
    }
  }

  .payment-list-wrap {
    .payment-main-wrap {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    table {
      thead {
        th {
          text-align: center;
        }
        .th-s {
          min-width: 130px;
          width: 130px;
        }
        .th-date {
          min-width: 180px;
          width: 180px;
        }
      }

      tbody {
        td {
          text-align: center;
        }
      }
    }
  }
}