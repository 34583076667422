.cr-footer {
  display: flex;
  flex-direction: column;
  margin: 2.4rem 2.8rem 0;

  .footer-top {
    display: flex;
  }
  .footer-bottom {
    margin-bottom: 3rem;
    p {
      margin: 0;
      font-weight: 450;
      font-size: 0.7rem;
      line-height: 1.2rem;
      color: #a3a5ad;
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    margin: 0.4rem 0;
    background-color: rgba(0, 0, 0, .05);
  }
}
