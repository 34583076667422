.influencer-grade {
  .filter-wrap {
    .filter-type-wrap {
      display: flex;

      button {
        width: 100%;
        &:not(:first-of-type) {
          margin-left: 1.6rem;
        }
      }
    }
  }
}