.user-level {
  .card {
    margin-top: 24px;
    position: relative;
    width: 100%;
    max-height: 700px;
    overflow: scroll;

    table {
      thead {
        tr {
          position: sticky;
          top: -1px;
          background-color: #D9D9D9;
          th {
            min-width: 80px;
          }
          .th-blank {
            width: unset;
            min-width: 0;
          }
          .th-name {
            min-width: 140px;
          }
          .th-s {
            min-width: 78px;
          }
          .th-m {
            min-width: 88px;
          }
          .th-l {
            min-width: 135px;
          }
          .th-xl {
            min-width: 215px;
          }

        }
      }
    }
  }
}