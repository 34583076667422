.filtering {
  padding: 0 1rem;
  border: 2px solid #b5b5bd;
  border-radius: 0.25rem;

  .filtering-wrap {
    display: flex;
    padding: 0.8rem 0.5rem;
    .filter-title {
      display: flex;
      padding-top: 0.4rem;
      font-size: 1rem;
      font-weight: 600;
      width: 10rem;
      min-width: 11rem;
    }

    .filter-body {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;

      .data-name {
        display: flex;
        align-items: center;
        padding: 0 0.7rem;
        font-size: 0.85rem;
        min-height: 23px;
        cursor: pointer;
      }

      .asc:after {
        content: " ▼";
      }

      .desc:after {
        content: " ▲";
      }

      .active {
        font-weight: 500;
        background: #6a82fb;
        border-radius: 1.5rem;
        color: #FFF;
      }
    }

    .filter-date {
      display: flex;
      align-items: center;
      gap: 10px;
      flex: 1;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.8rem;
      border-bottom: solid 2px #b5b5bd;
    }

    &:not(:first-of-type) {
      padding-top: 0;
    }
  }

  .search-n-list-size {
    gap: 30px;
  }

  .search-wrap {
    flex: 1;
    display: flex;
    gap: 10px;

    .custom-select-box {
      min-width: 144px;
      height: 38px;
      font-size: 16px;
      z-index: 999;
      transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      .select-label {
        border-radius: 0.25rem;
      }

      .select-label:focus {
        box-shadow: 0 0 0 0.3rem rgba(128, 149, 252, 0.5);
      }
    }

    .search-input {
      height: 38px;
      font-size: 16px;
      line-height: 20px;
    }
    .search-btn {
      width: 100px;
      min-width: 100px;
      font-size: 16px;
      svg {
        margin-left: 8px;
      }
    }
    .reset-btn {
      min-width: 70px;
      font-size: 16px;
      color: #FFFFFF;
    }
  }

  .list-size-wrap {
    display: flex;
    flex: 1;
    .filter-title {
      align-items: center;
    }

    .custom-select-box {
      height: 38px;
      width: 100%;
      font-size: 16px;
      z-index: 999;
      transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      .select-label {
        border-radius: 0.25rem;
      }

      .select-label:focus {
        box-shadow: 0 0 0 0.3rem rgba(128, 149, 252, 0.5);
      }
    }
  }
}