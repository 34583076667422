.group-buying {
  .title {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .summary-wrap {
    margin-top: 24px;
    & > .card {
      gap: 16px;
    }
    .summary {
      flex: 1;
      text-align: center;

      .summary-title {
        font-weight: bold;
      }

      .summary-value {
        margin-top: 16px;
        b {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  .filtering-wrap {
    margin-top: 24px;
    .state-wrap {
      display: flex;
      gap: 16px;
      margin-bottom: 24px;
      button {
        flex: 1;
      }
    }
  }

  .table-wrap {
    margin-top: 24px;
    .group-buying-table {

      table {
        .t-date {
          min-width: 120px;
        }

        .t-s {
          min-width: 100px;
        }

        .t-m {
          min-width: 140px;
        }

        .t-l {
          min-width: 180px;
        }

        tr {
          text-align: center;
        }

        tbody {
          .link {
            color: #6E97FF;
            cursor: pointer;
          }
        }
      }
    }
  }
}