.keyword {
  table {
    thead {
      .th-small {
        width: 128.8px;
        text-align: center;
      }
      .th-keyword {
        width: 281.8px;
      }
    }
    tbody {
      .td-small {
        text-align: center;
      }
      td {
        vertical-align : middle;
        &:first-of-type {
          width: 50px;
        }
        &:last-of-type {
          width: 50px;
        }
      }
    }
  }
}