.contents-studio {
  .summary-wrap {
    & > .card {
      gap: 16px;
    }
    .summary {
      flex: 1;
      text-align: center;
      .summary-title {
        font-weight: bold;
      }

      .summary-value {
        margin-top: 16px;
      }
    }
  }

  .table-wrap {
    margin-top: 24px;
    table {
      thead {
        .th-select {
          width: 10rem;

          .form-control {
            max-width: 10rem;
            display: flex;
            background-color: transparent;
            border: none;
            padding: 0;
            height: unset;
            color: unset;
          }
        }
      }
      tbody {
        .dislike {
          color: red;
          cursor: pointer;
        }
      }
    }
  }
}