.user-stats {
    .count-stats {
        padding: 30px;
        margin-bottom: 30px;

        .bottom,
        .top {
            display: flex;
            gap: 15px;
            padding: 0 15px;
        }

        .top {
            margin-bottom: 15px;
        }

        .card {
            flex: 1;
            text-align: center;
            .value {
                margin-top: 10px;
            }
        }
    }
    .bar-wrap {
        flex-direction: column;
        gap: 30px;
        margin: 0;
        & > .card {
            padding: 30px;
        }
    }
}