.group-buying-mange-modal {
  .modal-header {
    .modal-title {
      .sub-title {
        font-size: 13px;
        font-weight: 500;
        margin-top: 10px;
      }
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: scroll;
    max-height: 700px;
    textarea {
      resize: none;
      outline: none;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      border: 1px solid #ced4da;
      height: 130px;
      border-radius: 0.25rem;
    }
    .important {
      color: red;
      padding-left: 10px;
    }
    .select-reject {
      .row {
        margin-top: 10px;
      }
    }
    .card {
      gap: 14px;
      background-color: #F9F9F9;
      .data-wrap {
        display: flex;
        justify-content: space-between;
        .value,
        .key {
          font-size: 14px;
          color: #666666;
          line-height: 1.2;
        }
        .value {
          font-weight: 700;
        }
      }
      .data-etc {
        flex-direction: column;
        gap: 10px;
        .value {
          padding: 1rem;
          border-radius: 8px;
          border: 1px solid #CCCCCC;
        }
      }
      .user-table {
        table {
          tr {
            text-align: center;
          }
          .t-s {
            width: 110px;
          }
          .t-btn {
            width: 55px;
          }
        }
        .page-button {
          button {
            min-width: 2.8rem;
            height: 2rem;
            line-height: 1.2;
          }
        }
      }
      .link {
        color: #6E97FF !important;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80%;
        text-align: right;
      }
    }
  }
}