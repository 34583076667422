@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: normal;
  src: local('Noto Sans CJK KR'),
    url('./styles/fonts/NotoSansCJKkr-hinted/NotoSansCJKkr-Regular.otf')
      format('opentype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans CJK KR'),
    url('./styles/fonts/NotoSansCJKkr-hinted/NotoSansCJKkr-Medium.otf')
      format('opentype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: bold;
  src: local('Noto Sans CJK KR'),
    url('./styles/fonts/NotoSansCJKkr-hinted/NotoSansCJKkr-Bold.otf')
      format('opentype');
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 200;
  src: local('SUIT'),
    url('./styles/fonts/SUIT-woff2/SUIT-Thin.woff2')
      format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 200;
  src: local('SUIT'),
    url('./styles/fonts/SUIT-woff2/SUIT-ExtraLight.woff2')
      format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 300;
  src: local('SUIT'),
    url('./styles/fonts/SUIT-woff2/SUIT-Light.woff2')
      format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: normal;
  src: local('SUIT'),
    url('./styles/fonts/SUIT-woff2/SUIT-Regular.woff2')
      format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 500;
  src: local('SUIT'),
    url('./styles/fonts/SUIT-woff2/SUIT-Medium.woff2')
      format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 600;
  src: local('SUIT'),
    url('./styles/fonts/SUIT-woff2/SUIT-SemiBold.woff2')
      format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: bold;
  src: local('SUIT'),
    url('./styles/fonts/SUIT-woff2/SUIT-Bold.woff2')
      format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 800;
  src: local('SUIT'),
    url('./styles/fonts/SUIT-woff2/SUIT-ExtraBold.woff2')
      format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 900;
  src: local('SUIT'),
    url('./styles/fonts/SUIT-woff2/SUIT-Heavy.woff2')
      format('woff2');
  font-display: swap;
}
