.influencer {
  .influencer-list {
    .table-wrap {
      table {
        thead {
          .th-l {
            width: 300px;
            min-width: 300px;
          }
          .th-num{
            width: 120px;
            min-width: 120px;
          }
          .th-btn {
            min-width: 110px;
          }
        }
        tbody {
          td {
            vertical-align: unset;
          }
          .influencer-info {
            display: flex;
            cursor: pointer;
            align-items: center;
            .image-wrap {
              margin-right: 0.6rem;
              img {
                max-width: 80px;
                min-width: 80px;
                max-height: 80px;
                min-height: 80px;
                border-radius: 50%;
              }
            }
            .text-wrap {
              .text {
                span + span {
                  margin-left: 4px;
                }
              }
              .text + .text {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }

  .interaction-wrap {
    display: flex;
    gap: 16px;

    .interaction-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      .interaction-key,
      .interaction-value {
        font-size: 16px;
      }

       .interaction-key {
         font-weight: 700;
       }

      .interaction-value {
        text-align: end;
      }
    }
  }
}