.influencer-card {
  .influencer-detail-card,
  .sns-contents-detail-card {
    .desc-wrap {
      background-color: #CCCCCC;
      border-radius: 0.25rem;
      .desc {
        font-weight: 600;
        line-height: 1.3;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .desc-full {
        -webkit-line-clamp: unset;
      }

      .show-hidden {
        margin-top: 8px;
        text-align: end;
        color: #8200FF;
        span {
          cursor: pointer;
          font-weight: 700;
        }
      }
    }
  }

  .influencer-detail-card {
    gap: 40px;

    .thumbnail-wrap {
      width: 250px;
      min-width: 250px;
      height: 250px;
      text-align: center;
      line-height: 250px;
      background-color: #DDDDDD;
      border-radius: 0.25rem;
      border: #999 1px solid;
      img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: contain;
      }
    }

    .influencer-info-wrap {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .nickname-wrap {
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #333;
        span {
          font-size: 20px;
          font-weight: 700;

          .sns-id {
            font-size: 22px;
            padding-right: 10px;
            margin-right: 10px;
            border-right: 2px solid #333;
          }
        }
      }

      .info-main-wrap {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .info-wrap {
          gap: 30px;

          .info {
            width: 100%;
            .info-key {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .sns-contents-detail-card {
    gap: 30px;
    .image-form {
      width: 200px;
      min-width: 200px;
      height: 200px;
      min-height: 200px;
      border-radius: 0.25rem;
      background-color: black;

      img {
        width: 200px;
        height: 200px;
        border-radius: 0.25rem;
        object-fit: contain;
      }
    }
    .right-wrap {
      gap: 10px;

      .card-title {
        font-size: 20px;
        font-weight: 600;
      }

      .middle-wrap {
        .influencer-info-wrap {
          align-items: center;
          gap: 10px;
          cursor: pointer;

          .influencer-image-form {
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            border-radius: 50%;
            background-color: gray;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }

          .nickname-date-wrap {
            &> div {
              font-size: 14px;
              line-height: 1.3;
              font-weight: 600;
            }
            .posting-date {
              color: #999999;
            }
          }

          .platform-icon {
            width: 40px;
            height: 40px;
          }
        }

        .crawling-btn {

        }
      }
    }
  }

  .influencer-sns-post-series-card {
    .post-series-card-header {
      display: flex;
      justify-content: space-between;
      .interaction-chart-date {
        max-width: 160px;
      }
    }

    .select-interation {
      display: flex;
      justify-content: end;
      gap: 10px;
      margin: 10px 0;
    }
  }
}