.influencer-invoice-modal {
  .prev-name {
    font-size: 14px;
    margin-left: 6px;
    word-break: break-all;
  }
  .modal-body {
    .search-wrap {
      gap: 8px;
      margin-bottom: 0.75rem;

      button {
        min-width: 70px;
      }
    }

    .input-wrap {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .combination-inputs {
      margin-bottom: 1rem;

      .combination-input {
        .desc {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          width: 50px;
          min-width: 50px;
          height: 38px;
          border: 1px solid #ced4da;
          border-right: 0;
          border-radius: 0.25rem 0 0 0.25rem;
        }
        input {
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }

      .combination-input + .combination-input {
        margin-top: 0.5rem;
      }
    }

    .table-wrap {
      table {
        thead {
          .th-check-box {
            width: 40px;
          }
        }

        tbody {
          .form-check-input {
            margin-left: 0;
            margin-top: 0;
          }
          .product-name {
            overflow: unset;
            text-overflow: unset;
            white-space: normal;
            word-break: break-all;
          }
        }
      }
    }
  }
}