.influencer-invoice {
  .card-title {
    font-size: 20px;
  }

  .underline {
    text-decoration: underline;
  }

  .btn-wrap {
    gap: 10px;
  }

  .required-data {
    color: #FF0000;
    font-weight: 600;
  }

  .error-massage {
    color: #FF0000;
    font-size: 12px;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }

  .error-massage-influencer {
    color: #FF0000;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0.75rem;
    text-align: center;
  }

  .filter-wrap {
    .invoice-type-wrap {
      gap: 16px;
      margin-bottom: 20px;
      button {
        flex: 1;
      }
    }
  }

  .invoice-issued-wrap {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .precautions {
      font-size: 20px;
      ul {
        padding-top: 12px;
        padding-inline-start: 20px;
        li {
          font-size: 12px;
          line-height: 1.3;
        }
      }

      .ex-excel {
        padding-top: 12px;
        font-size: 12px;
        color: gray;
      }
    }
    .invoice-btn-wrap{
      width: 200px;
      .btn-wrap {
        display: flex;
        justify-content: end;
        gap: 10px;
        input {
          display: none;
        }
      }
      .message {
        margin-top: 16px;
        font-size: 12px;
        color: red;
        text-align: end;
      }
      .file-name {
        margin-top: 16px;
        font-size: 12px;
        color: #999999;
        text-align: end;
      }
    }
  }

  .invoice-issued-result {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 30px;
    .error-result {
      font-size: 20px;
      ul {
        padding-top: 12px;
        padding-inline-start: 20px;
        li {
          font-size: 14px;
          line-height: 1.3;
        }
      }
    }

    .count-result {
      width: 300px;
      min-width: 300px;
      border: 1px solid lightgray;
      padding: 16px;
      ul {
        padding-inline-start: 20px;
        li {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }

  .invoice-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-size: 20px;
    .invoice-loader {
      width:120px;
      height:120px;
      border-radius:50%;
      padding:1px;
      background:conic-gradient(#0000 10%,#8200FF) content-box;
      -webkit-mask: repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
        radial-gradient(farthest-side,#0000 calc(100% - 11.5px),#000 calc(100% - 11px));
      -webkit-mask-composite: destination-in;
      mask-composite: intersect;
      animation:loader 1.5s infinite steps(10);
    }
    @keyframes loader {to{transform: rotate(1turn)}}
  }

  .invoice-success-list {
    padding: 20px;

    .btn-wrap {
      display: flex;
      gap: 10px;
      justify-content: end;
      padding: 10px 0;
    }

    .invoice-loading {
      flex-direction: row;
      gap: 8px;
      font-size: 16px;
      .invoice-loader {
        width:25px;
        height:25px;
        -webkit-mask: repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
          radial-gradient(farthest-side,#0000 calc(100% - 4.5px),#000 calc(100% - 4px));
        mask-composite: intersect;
      }
    }

    .table-wrap {
      padding: 10px;

      position: relative;
      max-height: 400px;
      overflow: scroll;

      table {
        .form-check-input {
          position: unset;
          margin: unset;
        }
        .check {
          text-align: center;
        }
        thead {
          .th-s {
            min-width: 40px;
            width: 40px;
          }
          .th-m {
            min-width: 100px;
            width: 100px;
          }
          .th-l {
            min-width: 130px;
            width: 130px;
          }
        }
      }
    }
  }


  .table-wrap {
    .pointer {
      cursor: pointer;
    }
    .card {
      padding-bottom: 20px;
    }
    table {
      .form-check-input {
        position: unset;
        margin: 0;
      }
      thead {
        .th-check-box {
          min-width: 38px;
        }
        .th-xl {
          min-width: 320px;
        }
        .th-l {
          min-width: 260px;
        }
        .th-m {
          min-width: 190px;
        }
        .th-s {
          min-width: 145px;
        }
        .th-xs {
          min-width: 115px;
        }
        .th-2xs {
          min-width: 90px;
        }
        .th-download {
          min-width: 120px;
        }
        .th-download-2 {
          min-width: 145px;
        }
        .th-date {
          min-width: 120px;
        }
        .th-date-range {
          min-width: 250px;
        }
      }
      tbody {
        .link-download {
          color: #0645AD;
        }
        .disable {
          color: #999999;
        }
        .tr-bank-account {
          cursor: pointer;
          &:hover {
            border: 1px solid #8200FF;
            td {
              border-top: 3px solid #8200FF;
              border-bottom: 1px solid #8200FF;
            }
          }
        }
        .tr-active {
          border: 1px solid #8200FF;
          td {
            border-top: 3px solid #8200FF;
            border-bottom: 1px solid #8200FF;
          }
        }
      }
    }
  }

  .sub-table {
    table {
      thead {
        .th-check-box {
          min-width: 38px;
          width: 38px;
        }
        .th-xl {
          min-width: 210px;
          width: 210px;
        }
        .th-l {
          min-width: 190px;
          width: 190px;
        }
        .th-m {
          min-width: 145px;
          width: 145px;
        }
        .th-s {
          min-width: 100px;
          width: 100px;
        }
        .th-xs {
          min-width: 80px;
          width: 80px;
        }
        .th-date {
          min-width: 126px;
          width: 126px;
        }
      }
      tbody {

      }
    }
  }

  .preview-wrap {
    padding: 20px;

    iframe {
      width: 100%;
      height: 700px;
    }
  }

  .invoice-detail-wrap {
    .form-group {
      margin-bottom: 0;
      .col {
        margin-bottom: 1rem;
      }
    }

    .tooltip-text {
      margin-left: 0.5rem;
      padding: 0 5.5px;
      border: 1px solid #8200FF;
      border-radius: 50%;
      font-size: 0.75rem;
      font-weight: 700;
      color: #8200FF;
    }

    .combination-inputs {
      margin-bottom: 1rem;

      .combination-input {
        .desc {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          width: 50px;
          min-width: 50px;
          height: 38px;
          border: 1px solid #ced4da;
          border-right: 0;
          border-radius: 0.25rem 0 0 0.25rem;
        }
        input {
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }

      .combination-input + .combination-input {
        margin-top: 0.5rem;
      }
    }
    .main-card-wrap {
      flex: 1;
      max-width: calc(50% - 15px);
    }

    .invoice-detail-card {
      gap: 30px;
      .card-title {
        width: 100%;
        padding-bottom: 4px;
        border-bottom: 1.5px solid black;
      }

      .date-post-wrap {
        gap: 10px;
        font-size: 16px;
        font-weight: 600;
        align-items: center;
        margin-bottom: 1rem;
      }

      .post-option-wrap {
        padding: 16px;
        margin-bottom: 1rem;

        textarea {
          resize: none;
          height: 180px;
          outline: none;
          border: 0;
          white-space: pre-wrap;
        }
      }

      .platform-wrap {
        gap: 0.5rem;
        margin: 0.5rem 0 1rem;

        .platform {
          gap: 10px;

          .remove-btn {
            width: 38px;
            height: 38px;
            font-weight: 600;
            font-size: 1.1rem;
            border-radius: 50%;
          }
        }
      }

      .currency-wrap {
        margin-bottom: 1rem;
      }

      .file-wrap {
        margin-bottom: 1rem;
        .btn-wrap {
          gap: 10px;
          .file-input {
            display: none;
          }
          .file-download {
            display: none;
          }
        }
      }
    }

    .invoice-brand-card {
      gap: 30px;

      .title-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.75rem;
        .card-title {
          margin-bottom: 0;
        }
        .btn-wrap {
          display: flex;
          gap: 8px;
        }
      }

      .table-wrap {
        thead {
          .th-m {
            min-width: 240px;
            width: 240px;
          }
          .th-btn {
            min-width: 95px;
            width: 95px;
          }
        }
        tbody {
          .td-btn {
            padding: 6px 10px;
          }
        }
      }
    }

    .influencer-list {
      .btn-main-warp {
        display: flex;
        justify-content: space-between;
        .btn-wrap {
          display: flex;
          gap: 10px;
          margin-bottom: 1rem;
        }
      }
      .table-wrap {
        table {
          thead {
            .th-m {
              min-width: 140px;
              width: 140px;
            }
            .th-s {
              min-width: 85px;
              width: 85px;
            }
            .th-btn {
              min-width: 95px;
              width: 95px;
            }
          }
          tbody {
            .td-btn {
              padding: 6px 10px;
            }
          }
        }
      }
    }

    .card + .card {
      margin-top: 20px;
    }
  }
}