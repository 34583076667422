.count-dashboard-card {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: #DDDDDD;
  border-radius: 0.5rem;
  width: 100%;

  .count-title {
    font-size: 14px;
    font-weight: 600
  }

  .count {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 12px;
      font-weight: 600;
    }
  }
}