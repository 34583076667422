.influencer-table-wrapper {
  &.post-list-wrap {
    .list-title {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .table-wrap {
      table {
        thead {
          .th-l {
            width: 600px;
            min-width: 600px;
          }

          .th-m {
            width: 120px;
            min-width: 120px;
          }

          .th-num {
            width: 140px;
            min-width: 140px;
          }

          .th-date {
            width: 130px;
            min-width: 130px;
          }

          .th-btn {
            min-width: 80px;
          }
        }
        tbody {
          .td-btn {
            padding: 4px;
          }

          .post-link {
            cursor: pointer;
            &:hover {
              color: #6E97FF;
            }
          }
        }
      }
    }
  }

  &.keyword-table-wrap {
    table {
      thead {
        .th-keyword {
          width: calc(50% - 70px);
        }
        .th-count {
          width: 140px;
        }
      }
    }
  }
}