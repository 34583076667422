.campaign-detail-card {
  .card-wrap {
    display: flex;
    gap: 30px;

    .image-wrap {
      position: relative;
      .campaign-state {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 48px;
        height: 48px;
        color: white;
        font-size: 13px;
        font-weight: 600;
        background-color: #8200FF;
        border-radius: 50%;
        top: 10px;
        right: 10px;
        transform: translate(50%, -50%);
      }

      .image-form {
        background-color: #333333;
        width: 200px;
        height: 200px;
        border-radius: 4px;
        img {
          width: 200px;
          height: 200px;
          object-fit: contain;
        }
      }
    }
    .campaign-data {
      width: 100%;
      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: 16px;
      }

      .basic-data-wrap {
        display: flex;
        gap: 16px;

        &> div {
          width: 100%;
        }

        .data-wrap {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .key, .value {
            font-size: 14px;
          }

          .badge-wrap {
            span {
              font-size: 13px;
              color: white;
            }
          }
        }

        .data-wrap + .data-wrap {
          margin-top: 10px;
        }
      }
    }
  }
}