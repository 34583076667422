/* Font Smoothing */
html {
  font-family: SUIT, serif;
  font-weight: normal;
  * {
    font-weight: normal;
    line-height: 1;
  }
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  font-family: SUIT, serif;
}

strong {
  font-weight: bold;
  * {
    font-weight: bold;
  }
}

.ql-container {
  font-size: 10px !important;
}

.ql-editor {
  font-size: 230%;
  * {
    line-height: 1.42;
  }
}

thead {
  .th-l {
    max-width: 18.8rem;
  }
  .th-m {
    width: 10.8rem;
  }
  .th-s {
    width: 5.2rem;
  }
  .th-blank {
    width: 2.4rem;
  }
}

tbody {
  .non-white-space {
    overflow: unset;
    white-space: unset;
  }
  td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.none-data,
.loader-body {
  margin: 16px auto;
  .none-title,
  .loader-title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.loader {
  margin: auto;
  border: 25px solid #D9D9D9;
  border-radius: 50%;
  border-top: 25px solid #8200FF;
  width: 150px;
  height: 150px;
  animation: spinner 4s linear infinite;
}

.sidebar-open {
  width: calc(100vw - 332px);
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}