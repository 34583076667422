.scroll-table-wrap {
  margin-top: 24px;
  position: relative;
  width: 100%;
  max-height: 700px;
  overflow: scroll;

  table {
    thead {
      tr {
        position: sticky;
        top: -1px;
        background-color: #D9D9D9;
      }
    }
  }
}