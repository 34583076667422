.payment-history {
  .filter-wrap {
    margin: 2rem 0;
  }

  .table-wrap {
    .history-wrap {
      width: 100%;
      overflow-x: scroll;
      overflow-y: unset;

      table {
        thead {
          th {
            white-space: pre;
            text-align: center;
          }
          .th-l {
            min-width: 300px;
          }
          .th-m {
            min-width: 200px;
          }
          .th-s {
            min-width: 130px;
          }
          .th-date {
            min-width: 200px;
          }
          .th-select {
            .custom-select-box {
              border: none;
            }
          }
        }

        tbody {
          tr {
            .none-data {
              font-size: 24px;
              font-weight: 500;
              line-height: 30px;
              padding-left: 20px;
            }
            td {
              text-align: center;
            }
            .negative {
              color: red;
            }
          }
        }
      }
    }
  }
}