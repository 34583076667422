.list_user {
  .title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .status-wrap {
    margin-bottom: 24px;
    .summary-warp {
      gap: 16px;

      .summary {
        flex: 1;
        text-align: center;
        .key {
          font-size: 16px;
          font-weight: bold;
        }

        .value {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }

  .filter-wrap {
    margin-bottom: 24px;
    .filtering {
      .search-wrap {
        .custom-select-box {
          min-width: 160px;
        }
      }
    }
    .filter-type-wrap {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;
      button {
        flex: 1;
      }
    }
  }

  .table-wrap {
    .card {

    }
    .user-list {

      table {
        .t-date {
          min-width: 115px;
        }

        .t-s {
          min-width: 130px;
        }

        .t-m {
          min-width: 145px;
        }

        .t-l {
          min-width: 160px;
        }

        .t-xl {
          min-width: 240px;
        }
        tr {
          text-align: center;
        }

        tbody {
          padding-bottom: 15px;
          td {
            padding: 0.75rem 0.3rem;
          }
        }
      }
    }
  }
}