.demo_apply {
  .summary-wrap {
    & > .card {
      gap: 16px;
    }

    .summary {
      flex: 1;
      text-align: center;

      .summary-title {
        font-weight: bold;
      }

      .summary-value {
        margin-top: 16px;
      }
    }
  }

  .table-wrap {
    table {
      thead {
        .th-s {
          width: 90px;
        }
        .th-m {
          width: 145px;
        }
        th {
          text-align: center;
        }
      }
      tbody {
        td {
          text-align: center;
        }
      }
    }
  }
}