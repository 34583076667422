.product {
  .title {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .sub-title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .summary {
    &:not(:first-of-type) {
      margin-top: 1rem;
    }

    .sub-title {
      text-align: center;
    }

    .value {
      text-align: center;
      margin-top: 0.5rem;
      font-size: 1rem;
    }
  }

  .filter-wrap {
    margin: 2rem 0;

    .filter-type-wrap {
      display: flex;

      button {
        flex: 1;
        font-weight: bold;

        &:not(:first-of-type) {
          margin-left: 1.5rem;
        }
      }
    }
    .main-filter-wrap {
      margin-top: 1.5rem;
    }
  }

  .table-wrap {
    table {
      thead {
        th {
          text-align: center;
        }
        .th-select {
          min-width: 120px;

          .form-control {
            max-width: 120px;
            display: flex;
            background-color: transparent;
            border: none;
            padding: 0;
            height: unset;
            color: unset;
          }
        }
        .th-date {
          min-width: 120px;
        }
        .th-xxl {
          min-width: 300px;
        }
        .th-xl {
          min-width: 230px;
        }
        .th-l {
          min-width: 210px;
        }
        .th-m {
          min-width: 150px;
        }
        .th-s {
          min-width: 110px;
        }
      }

      tbody {
        td {
           text-align: center;
        }
      }

      .form-check-input {
        position: unset;
        margin-top: 0;
        margin-left: 0;
      }
    }
  }
}