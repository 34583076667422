.campaign_table {
  .campaign-contents-table {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .table-wrap {
      table {
        thead {
          .th-num {
            width: 90px;
          }
          .th-btn {
            width: 97.8px;
          }
        }

        tbody {
          td {
            vertical-align: middle;
          }

          .td-btn {
            padding: 6px;
          }

          .contents-wrap {
            display: flex;
            gap: 20px;
            cursor: pointer;

            .image-form {
              width: 120px;
              height: 120px;
              background-color: #333333;
              border-radius: 4px;

              img {
                width: 120px;
                height: 120px;
                object-fit: contain;
              }
            }

            .contents-data {
              align-content: center;
              width: calc(100% - 140px);
              .contents-name {
                font-size: 16px;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 8px;
              }
              .profile-wrap {
                display: inline-flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 8px;

                img {
                  width: 48px;
                  height: 48px;
                  border-radius: 50%;
                }

                .profile {
                  p {
                    font-size: 13px;
                    line-height: 1.3;
                    margin-bottom: unset;
                  }
                }
                .sns-icon {
                  width: 40px;
                  height: 40px;
                }
              }

              .payment-wrap {
                display: flex;
                flex-wrap: wrap;

                .payment {
                  display: flex;
                  gap: 6px;

                  span {
                    font-size: 14px;
                    font-weight: 600;
                  }

                  .key {
                    color: #666666;
                    font-weight: 500;
                  }
                  &:not(:last-child) {
                    margin-right: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}