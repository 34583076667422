.influencer-section {
  .dashboard-title {
    font-size: 16px;
    font-weight: 600;
  }

  .divider-col {
    height: auto;
    width: 2px;
    background-color: #999999;
  }

  .posting-dashboard {
    .post-status-wrapper {
      display: flex;
      gap: 20px;

      .post-status-wrap {
        width: 100%;

        .title-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;

          span {
            font-size: 16px;
            font-weight: 600;
          }

          .custom-select-box {
            .select-label {
              width: 200px;
              padding-top: 8px;
              padding-bottom: 8px;
            }

            .option-item {
              padding: 6px 10px;
            }
          }
        }

        .status-wrap {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .status-card-wrap {
            display: flex;
            gap: 8px;
          }
        }
      }
    }
  }

  .comment-dashboard {
    .dashboard-title {
      margin-bottom: 10px;
    }

    .comment-status {
      display: flex;
      gap: 20px;
    }

    .keyword-table-wrapper {
      display: flex;
      width: 100%;
      gap: 16px;
      .keyword-table {
        width: 100%;
      }
    }

    .comment-list-wrap {
      .title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dashboard-title {
          font-size: 20px;
          margin-bottom: 0;
        }

        .positive-rate-wrap {
          display: flex;
          gap: 8px
        }
      }

      .comment-list {
        padding: 20px;
        border: 1px solid #999999;
        border-radius: 0.5rem;
        overflow-y: scroll;
        max-height: 700px;

        .comment-wrap {
          display: flex;
          flex-direction: column;
          border-top: 1px solid black;
          padding: 16px 0;
          gap: 16px;

          .comment-header {
            display: flex;
            justify-content: space-between;

            span {
              font-weight: 600;
            }

            .link-post {
              color: #6E97FF;
              text-decoration: underline;
              cursor: pointer;
            }
          }

          .comment-middle {
            display: flex;
            gap: 20px;
          }
        }
      }
    }
  }

  .interaction-dashboard,
  .campaign-dashboard,
  .invoice-dashboard {
    .title-wrap {
      display: flex;
      align-content: center;
      justify-content: space-between;
      input {
        width: 250px;
      }
    }
  }

  .interaction-dashboard {
    .interaction-btn-wrap {
      display: flex;
      justify-content: end;
      gap: 8px;
    }
  }

  .invoice-dashboard {
    .invoice-by-influencer-wrap {
      .invoice-table {
        table {
          thead {
            .th-m {
              width: 180px;
              min-width: 180px;
            }

            .th-l {
              width: 450px;
              min-width: 450px;
            }

            .th-date {
              width: 120px;
              min-width: 120px;
            }

            .th-link {
              width: 115px;
              min-width: 115px;
            }
          }

          tbody {
            .link {
              color: #6E97FF;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .interaction-wrap {
    display: flex;
    gap: 16px;

    .interaction-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      .interaction-key,
      .interaction-value {
        font-size: 16px;
      }

       .interaction-key {
         font-weight: 700;
       }

      .interaction-value {
        text-align: end;
      }
    }
  }

  .influencer-post-comment-section {
    .dashboard-title {
      font-size: 20px !important;
      margin-bottom: 10px;
    }

    .comment-filter-wrap {
      margin-bottom: 20px;
    }

    .comment-list-wrap {
      border-top: 1px solid #DDDDDD;
      border-bottom: 1px solid #DDDDDD;
      max-height: 550px;
      overflow-y: scroll;
      .comment-card {
        .comment-top {
          .comment-title {
            font-size: 16px;
            font-weight: 600;
          }
        }
        .middle-wrap {
          display: flex;
          gap: 8px;
          margin-top: 8px;
          .positive-rate {
            &.positive {
              color: #6E97FF;
            }
            &.negative {
              color: #FF6ED6;
            }
            &.normal {
              color: #00E29E;
            }
          }
          .comment {
            color: #666666;
          }
        }
        .comment {
          margin-top: 10px;
          line-height: 1.3;
          background-color: #DDDDDD;
          border: 1px solid #CCCCCC;
          border-radius: 0.25rem;
        }
      }
      .comment-card + .comment-card {
        border-top: 1px solid #DDDDDD;
      }
    }
  }

  .payment-dashboard {
    .payment-type-wrap {
      display: flex;
      gap: 20px;
      button {
        width: 100%;
      }
    }
  }

  .campaign-dashboard {
    .influencer-campaign-contents {
      .campaign-table {
        table {
          thead {
            .th-pay {
              width: 200px;
              min-width: 200px;
            }

            .th-m {
              width: 170px;
              min-width: 170px;
            }

            .th-l {
              width: 450px;
              min-width: 450px;
            }

            .th-date {
              width: 120px;
              min-width: 120px;
            }

            .th-link {
              width: 110px;
              min-width: 110px;
            }
          }

          tbody {
            .link {
              color: #6E97FF;
              text-decoration: underline;
              cursor: pointer;
            }

            .disable {
              color: #999999;
              cursor: unset;
            }
          }
        }
      }
    }
  }
}